/* eslint-disable max-lines */

const { $, lozad, Swiper } = window;

$(document).ready(function() {
    // Lazy Loading
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'

    observer.observe();

    // Mobile menu
    $('#main-nav').clone().removeClass().prependTo('.mobile-nav-panel .oc-panel__main');

    $('.mobile-menu-button, .oc-panel__overlay').click(function(e) {
        e.preventDefault();

        $('.mobile-nav-panel').toggleClass('active');
        $('body').toggleClass('oc-panel-opened');
    });

    // Transparent Header
    if ($('.header--transparent').length > 0) {
        $('.header--transparent').addClass('js-header-transparent');

        $(window).scroll(function() {
            if ($(window).scrollTop() > 200) {
                $('.js-header-transparent').removeClass('header--transparent');
            } else {
                $('.js-header-transparent').addClass('header--transparent');
            }
        });
    }

    // Logos Slideshow
    if ($('.logos-slideshow').length > 0) {
        new Swiper('.logos-slideshow', {
            breakpoints: {
                1112: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
            initialSlide:3,
            slidesPerView: 7,
            spaceBetween: 30,
        });
    }

    // Select Dropdowns Styling
    $('.custom-select1').select2({
        containerCssClass: 'select-style-1',
        dropdownCssClass:  'selectdrop-style-1',
    });


    // About Slideshow
    if ($('.about-slideshow').length > 0) {
        new Swiper('.about-slideshow', {
            autoplay: {
                delay: 2000,
            },
            freeMode: true,
            lazy: {
                loadPrevNext: true,
            },
            loop: true,
            loopedSlides: 6,
            preloadImages: false,
            slidesPerView: 'auto',
            spaceBetween: 30,
            speed: 1000,
        });
    }

    // Reviews Slideshow
    if ($('.reviews-slideshow').length > 0) {
        new Swiper('.reviews-slideshow', {
            autoplay: {
                delay: 2000,
            },
            freeMode: true,
            loop: true,
            loopedSlides: 6,
            slidesPerView: 'auto',
            spaceBetween: 30,
            speed: 1000,
        });
    }

    // Report Slideshow
    if ($('.report-slideshow').length > 0) {
        new Swiper('.report-slideshow', {
            freeMode: true,
            navigation: {
                nextEl: '.report-slideshow .swiper-button-next',
                prevEl: '.report-slideshow .swiper-button-prev',
            },
            slidesPerView: 'auto',
            spaceBetween: 25,
        });
    }

    // Gallery Popup
    $('.gallery-popup').magnificPopup({
        gallery:{
            enabled:true,
        },
        mainClass: 'mfp-fade',
        removalDelay: 300,
        type: 'image',
    });

    // Videos Responsive
    $('.video-cont').fitVids();

    // Review Cards
    if ($('.review-card').length > 0) {
        $('.review-cards .grid').isotope({
            itemSelector: '.grid-item',
            masonry: {
                // use element for option
                columnWidth: '.grid-sizer',
            },
            percentPosition: true,
        });
    }

    // Accordions
    $('.accordion__item__toggle').click(function(e) {
        e.preventDefault();

        $(this).parent().toggleClass('active');
        $(this).next('.accordion__item__content').slideToggle();
    });

    // Galleries
    if ($('.gallery__top.enabled').length > 0) {
        const galleryTop = new Swiper('.gallery__top', {
            navigation: {
                nextEl: '.gallery__thumbs__cont .gallery__button--next',
                prevEl: '.gallery__thumbs__cont .gallery__button--prev',
            },
            on: {
                slideChange: function() {
                    const activeIndex = this.activeIndex;

                    $(galleryThumbs.slides).removeClass('is-selected');
                    $(galleryThumbs.slides).eq(activeIndex).addClass('is-selected');

                    galleryThumbs.slideTo(activeIndex, 500, false);
                },
            },
            slideToClickedSlide: false,
            slidesPerView: 1,
            spaceBetween: 0,

        });

        const galleryThumbs = new Swiper('.gallery__thumbs', {
            centeredSlides: false,
            freeMode:true,
            on: {
                init: function() {
                    $(this.slides).eq(this.activeIndex).addClass('is-selected');
                },
                tap: function() {
                    const clicked = this.clickedIndex;

                    this.activeIndex = clicked; //don't need this
                    this.updateSlidesClasses(); //don't need this

                    $(this.slides).removeClass('is-selected');
                    $(this.clickedSlide).addClass('is-selected');

                    galleryTop.slideTo(clicked, 500, false);
                },
            },
            preventClicks:false,
            slidesPerView: 'auto',
            spaceBetween: 16,
            touchRatio: 0.2,

        });
    }

    // Report Tabs
    $('.report-tab-links a').click(function(e) {
        e.preventDefault();

        const $tabscont = $(this).closest('.report-tabs-cont');
        const $tabslinks = $tabscont.find('.report-tab-links a');
        const $tabs = $tabscont.find('.report-tab');

        if (!$(this).hasClass('active')) {
            $tabslinks.removeClass('active');

            $(this).addClass('active');

            $tabs.removeClass('active');

            $tabs.eq($(this).parent().index()).addClass('active');
        }
    });

    // Pricing Crds
    if ($('.container--pricing').length > 0) {
        $('.container--pricing .pricing__box ul.pricing-list').matchHeight({
            byRow: false,
            property: 'height',
            remove: false,
            target: null,
        });
    }

    // HOme MOuse Icon
    $('.js-mouse').click(function(e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $('#logos').offset().top + 73,
        }, 1000, function() {});
    });

    if ($('.hero-banner--video').length > 0) {
        setTimeout(function() {
            $('.hero-banner--video').prepend(`
                <video id="bgvid" playsinline autoplay muted loop>
                    <source src="/spotmechanic/main/videos/spotmechanic-trailer-noaudio.webm" type="video/webm">
                    <source src="/spotmechanic/main/videos/spotmechanic-trailer-noaudio.mp4" type="video/mp4">
                </video>
            `);
        }, 2000);
    }

    // Contact Form
    const contactForm = $('#sm-contact-form');

    $(contactForm).submit(function(event) {
        event.preventDefault();

        const formData = $(contactForm).serialize();

        $('#contact-form').addClass('form--loading');

        $.ajax({
            data: formData,
            type: 'POST',
            url: $(contactForm).attr('action'),
        }).done(function() {
            $('#contact-form').removeClass('form--loading');
            $('#contact-form').addClass('form--success');
        }).fail(function(data) {
            $('#contact-form').removeClass('form--loading');

            let errorInputs = Object.keys(data.responseJSON.errors);

            $('.form-group span.error').remove();

            errorInputs.forEach(input => {
                $('.form-group.' + input)
                    .append('<span class="error ' + input + '">' + data.responseJSON.errors[input] + '</span>');
            });
        });
    });

    // gdpr Form
    if ($('#gdpr-form').length > 0) {
        const gdprForm = $('#gdpr-form');

        $(gdprForm).submit(function(event) {
            event.preventDefault();

            const formData = $(gdprForm).serialize();

            $('#gdpr-form').addClass('form--loading');
            $.ajax({
                data: formData,
                type: 'POST',
                url: $(gdprForm).attr('action'),
            }).done(function() {
                gdprForm.removeClass('form--loading');
                gdprForm.addClass('hidden');

                $('.gdpr-form-thank-you').fadeIn();
            }).fail(function(data) {
                gdprForm.removeClass('form--loading');

                if (data.responseText !== '') {
                    console.log(data.responseText);
                } else {
                    console.log('error');
                }
            });
        });
    }

    // Latest Reports Filters
    $('.report-filters__toggle,.report-filters__close').click(function(e) {
        e.preventDefault();

        $('body').toggleClass('report-filters-opened');
        $('.report-filters').toggleClass('active');
    });

    // Latest Reports - Show More Findings Button
    $('.js-show-more-findings').click(function(e) {
        e.preventDefault();

        $(this).closest('ul').find('.hidden').removeClass('hidden');
        $(this).closest('li').addClass('hidden');
    });

    // Latest Reports - Show More Makes Filter
    $('.js-more-makes').click(function(e) {
        e.preventDefault();

        $(this).closest('.report-filters__main').find('li.hidden').removeClass('hidden');
        $(this).hide();
    });

    // Global Inline Popups
    if ($('.open-inline-popup').length) {
        $('.open-inline-popup').magnificPopup({
            mainClass: 'mfp-fade mfp-simple-popup',
            midClick: true,
            removalDelay: 300,
            type:'inline', // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });
    }

    // Global Notification temp
    if ($('.globalNotification').length) {
        $('body').css('padding-top', $('.globalNotification').outerHeight() + 'px');
    }
});